.contactSection {
  .contact {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .hello {
      text-decoration: none;
      border: solid 2px var(--accentColor);
      color: var(--accentColor);
      border-radius: 10px;
      padding: 1.25rem 1.75rem;
      animation: jello-horizontal 5s infinite both;
    }
  }
  footer {
    padding-top: 90px;
    .socials {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      column-gap: 0.25em;
      li {
        padding: 0 15px;
      }
    }
  }
}

@keyframes jello-horizontal {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  17% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  33% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  45% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  55% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
}
