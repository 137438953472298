.projects-list {
  list-style: none;
  display: grid;
  grid-auto-flow: row;
  gap: 5rem;
}
.folder {
  background-color: var(--darker-background) !important;
  border-radius: 10px;
  color: var(--textShade200) !important;
}
.folder:hover {
  transform: scale(1.05);
  transition: transform 0.25s ease-out;
  box-shadow: var(--hero-text-shadow);
}

.slide-in-right {
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-left {
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@media screen and (max-width: 415px) {
  .list-item {
    min-width: 320px;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
