.about-me {
  .leader {
    font-size: var(--headingMd);
    font-weight: 300;
  }
  p {
    max-height: 200px;
  }
}
.tagCloud {
  color: var(--accentColor);
  .tagcloud {
    width: 100% !important;
    bottom: 10%;
  }
}

.res-margin {
  margin-top: 45px;
}

@media (max-width: 530px) {
  .res-margin {
    margin-top: 120px;
  }
}
