.projectItem {
  display: grid;
  grid-auto-flow: row;
  gap: 5rem;
}
.imgContainer {
  position: relative;
  width: 65%;
  min-height: 400px;

  img {
    filter: brightness(0.5);
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  img:hover {
    filter: brightness(1);
  }
}

.imgPosition {
  left: 33%;
}

.textRight {
  right: 0%;
}

.textAlign {
  text-align: right;
  align-items: flex-end;
}

.textLeft {
  left: -4%;
}

.textContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background-color: transparent;
  height: 100%;
  background-color: var(--screenColor);
  overflow-x: hidden;
  .title {
    color: var(--textShade300);
    font-size: var(headingL);
    font-weight: 600;
  }
  .summary {
    border-radius: var(--border-radius);
    color: var(--textShade200);
    background-color: var(--darker-background);
    border-radius: 0.375rem;
  }
}

.tools {
  background-color: rgb(255 255 255/0.05);
  opacity: 1;
  backdrop-filter: blur(8px);
  color: var(--textShade200);
  list-style: none;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
  border-radius: 0.375rem;
  flex-wrap: wrap;
  gap: 1rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  .tool::before {
    content: "•";
    color: var(--accentColor);
  }
  .tools::before {
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
    filter: blur(10px);
  }
}
.links {
  gap: 0.75rem;
}

.slide-in-right {
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-left {
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@media screen and (max-width: 768px) {
  .imgContainer {
    width: 100%;
    min-height: 350px;
    height: 100%;
    left: 0;
  }

  .textContainer {
    width: fit-content;
    height: fit-content;
    min-height: 350px;
    text-align: left;
    display: flex;
    flex-direction: column;
    right: auto;
    align-items: center;
    padding: 0 !important;
    left: 0;
    .summary {
      background-color: transparent;
    }
  }
}

@media screen and (min-width: 768px) {
  .textContainer {
    background-color: transparent;
    max-width: 50%;
    height: fit-content;
  }
}

@media screen and (max-width: 415px) {
  .textContainer,
  .imgContainer {
    left: -55px;
  }
}
@media screen and (max-width: 380px) {
  .textContainer,
  .imgContainer {
    left: -70px;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
