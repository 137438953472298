.content {
  text-align: center;
  animation: slideInLeft;
  position: relative;
}
.hero {
  animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s both;
  white-space: nowrap !important;
}
.profession {
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.5s both;
  white-space: nowrap !important;
}

.img-content {
  position: relative;
  margin-left: 30px;
}

.picture::before,
.picture::after,
.picture {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.picture {
  width: 200px;
  height: 200px;
  margin: auto;
  background: url("https://res.cloudinary.com/daj4pxkwx/image/upload/v1661209680/personal/profileImage_xzma0j.jpg")
    no-repeat 52%/99% rgba(0, 0, 0, 0.1);
  color: var(--accentColor);
  box-shadow: inset 0 0 0 1px var(--accentColor);
}
.picture::before,
.picture::after {
  content: "";
  z-index: 1;
  margin: -5%;
  box-shadow: inset 0 0 0 2px;
  animation: clipMe 8s linear infinite;
}
.picture::before {
  animation-delay: -4s;
}
.picture:hover::after,
.picture:hover::before {
  background-color: rgba(255, 0, 0, 0.3);
}

@keyframes clipMe {
  0%,
  100% {
    clip: rect(0px, 220px, 2px, 0px);
  }
  25% {
    clip: rect(0px, 2px, 220px, 0px);
  }
  50% {
    clip: rect(218px, 220px, 220px, 0px);
  }
  75% {
    clip: rect(0px, 220px, 220px, 218px);
  }
}

@media screen and (max-width: 415px) {
  .hero {
    font-size: clamp(3.5rem, 7vw, 6rem) !important;
  }
  .profession {
    font-size: clamp(2.2rem, 4vw, 3rem) !important;
  }
}

@media screen and (max-width: 700px) {
  .content {
    flex-direction: column;
  }
  .img-content {
    margin-top: 100px;
    margin-left: 0;
    margin-bottom: 30px;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
