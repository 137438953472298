.theme {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  z-index: 9999;
  color: red;
  bottom: 5%;
  left: 12px;
  background-color: var(--background-color-inverse);
  border: 1px solid var(--accentColor);
  transition: transform 0.2s;
  opacity: 1;
  box-shadow: 2px 10px 5px -5px #000;
}

.theme:hover {
  transform: scale(1.1);
}
